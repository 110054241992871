import React, { useState,useEffect,Suspense } from "react";
import { navigate, graphql,Link } from "gatsby";

import SEO from '../../components/SEO'
import loadable from '@loadable/component'
import Whatsapp from "../../components/Whatsapp";
import axios from "axios";
import { api } from "../../config/api";



const BookingBod = loadable(
  () => import('../../components/BookingFlow'),
  {
    fallback: <div class="loaderBod"><div class="loader2"></div></div>
  }
  )


function Graph(props){
  
  const [loading, setloading] = useState(true);
  const [dataD, setData] = useState(props);

  useEffect(() => {

    setloading(true);
    axios.get(api.url+'booking').then(x=>{
      props.data.strapiBooking.Pricings = x.data.Pricings;
      console.log("PROP",props)
      setloading(false);
    }).catch(console.error)
    
  }, [])
  
  return <><SEO title={props.data.strapiServices.title+" Booking"}/>
   {!loading?<BookingBod {...props}/>:
          <div className="loaderStyle">
            <div class="loader"></div>
          </div>
        }
   <Whatsapp/></>
}


export const query = graphql`
  query Query($slug: String!) {

    file(name: {eq: "short-logo"}) {
      childrenImageSharp {
        fixed(height: 300) {
          src
        }
      }
    }

    strapiServices(slug: { eq: $slug }) {
      id
      slug
      title

      youtubeLink
      icon {
        localFile {
          publicURL
        }
      }
      image {
        localFile {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1900) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    allStrapiServices {
      nodes {
        slug
        title
      }
    }
    
    strapiTermsAndConditions {
      body
    }

    strapiBooking {
      SafetyFee
      LocationSize {
        size
      }
      Locations {
        city
        CommercialPricing
      }
      Timings {
        time
      }
      Pricings {
        areaPricing {
          economyPrice
          locationSize
          premiumPrice
        }
        service {
          id
          title
        }
      }
    }
  }
`;

export default Graph;
